@import "styles/mixins";

//#region Hits

.ais-Hits-item {
  display: block;
  flex-direction: column;
  gap: 10px;
  border: 1px solid transparent;
  padding: 0px;
  background-color: transparent;
  box-shadow: none;
}

.shop-product-grid {
  
  padding-inline: 1rem;
  @include tablet() {
    padding-inline: 0.5rem;
  }
  .ais-Hits-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 221px), 1fr));
    margin-inline: auto;
    gap: 1rem;
    @include tablet() {
      grid-template-columns: repeat(auto-fill, minmax(min(100%, 200px), 1fr));
      gap:0.5rem;
    }
    @include mobile() {
      grid-template-columns: repeat(auto-fill, minmax(min(100%, 150px), 1fr));
    }
  }
}

//#endregion

//#region Refinement lists

.ais-CurrentRefinements {
  // border-bottom: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius-sm);
  background: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  color: var(--clr-neutral-800);
  padding: 10px 10px;
  &:before {
    font-size: var(--fs-300);
    content: "Applied Filters:";
    font-weight:  600;
  }
}

.ais-CurrentRefinements--noRefinement {
  border-bottom: 0px;
  margin-top: 0em;
  display: none;
  &:before {
    content: "";
  }
}

.ais-CurrentRefinements-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  grid-auto-flow: column;
  margin: 0.5em 0.5em 0.5em 0em;
}

.ais-CurrentRefinements-delete {
  color: var(--clr-primary-600);
  font-weight:  600;
  height: 100%;
  border: none;
  background: transparent;
  &:hover {
    color: var(--clr-danger) !important;
  }
}

.ais-CurrentRefinements-item {
  display: flex;
  flex-wrap: wrap;
  background: none;
  border: none;
  padding: 0;
  border-radius: 0;
  gap: 5px;
}

.ais-CurrentRefinements-label {
  display: none;
}

.ais-CurrentRefinements-category {
  display: flex;
  gap: 5px;
  align-items: center;
  background: var(--clr-primary-100);
  color: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  font-size: 0.75rem;
  padding: 0.4em 0.6em;
  border-radius: 100px;
  margin: 0;
  text-transform: capitalize;
  height: fit-content;
  line-height: 0.875rem;
  &:hover {
    cursor: default;
    background-color: var(--clr-primary-300);
    .ais-CurrentRefinements-delete {
      color: var(--clr-primary-100);
    }
  }
}


//#endregion

//#region SearchBox

.ais-SearchBox-input {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid var(--clr-neutral-250);
  border-radius: 3px;
  box-shadow: none;
  color: #23263b;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  padding-left: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    border-color: var(--clr-neutral-300);
  }
}

.ais-SearchBox-input:focus,
.ais-SearchBox-input:focus-visible,
.ais-SearchBox-input:focus-within {
  border-color: var(--clr-primary-300);
  outline: none;
  @include mobile() {
    width: 100px;
  }
}

.ais-SearchBox {
  width: 100%;
  .ais-SearchBox-form:before {
    cursor: default;
  }
  .ais-SearchBox-input {
    cursor: text;
  }
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-form {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  line-height: 1.25rem;
  position: relative;
  width: 100%;
}

.ais-SearchBox-form:before {
  background: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
    repeat scroll 0 0;
  content: "";
  height: 1rem;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem;
}
.ais-SearchBox-reset[hidden] {
  display: none;
}
.ais-SearchBox-reset {
  align-items: center;
  display: flex;
  fill: var(--clr-neutral-800);
  background-color: transparent;
  height: calc(100% - 2px);
  justify-content: center;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  border: none;
  border-radius: var(--border-radius-sm);
  &:hover {
    fill: var(--clr-danger);
    cursor: pointer;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

//#endregion

//#region HitsPerPage + Sortby

.ais-HitsPerPage-select,
.ais-SortBy-select {
    background-color: white;
    color:var(--clr-neutral-800);
    border-color: var(--clr-neutral-250);
    border-radius:100px;
    padding: 4px 14px;
    height: 100%;
    font-size: 0.9375rem;
    font-weight:500;
    z-index: 1;
  &:hover {
    cursor: pointer;
    border-color: var(--clr-neutral-300);
    background-color: var(--clr-neutral-150);
  }
  @include mobile() {
    font-size:0.875rem;
  }
}

.ais-HitsPerPage-select:focus,
.ais-HitsPerPage-select:focus-visible,
.ais-HitsPerPage-select:focus-within,
.ais-SortBy-select:focus,
.ais-SortBy-select:focus-visible,
.ais-SortBy-select:focus-within {
  border-color: var(--clr-primary-300);
  outline: none;
  @include mobile() {
    width: 100vh;
  }
}
//#endregion

//#region pagination
.ais-Pagination-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // should change this out for max # of pages on mobile
  gap: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  margin: 3em 0em;
}

.ais-Pagination-link {
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--border-radius-sm);
  box-shadow: none;
  color: var(--clr-neutral-700);
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 1rem;
  place-items: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    border-color: var(--clr-primary-300);
  }
}

.ais-Pagination-item--selected .ais-Pagination-link {
  border-color: var(--clr-primary-300);
  background-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
}
//#endregion

//#region custom current refinements
.currentRefinements {
  display:flex;
  align-items: center;
  gap:1rem;
  padding: 10px;
  border:none;
  padding:0em;
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

  }
  span {
    display: flex;
    align-items: center;
    gap: 6px;
    background: var(--clr-secondary-300);
    color: var(--clr-neutral-100)!important;
    font-size: 0.75rem;
    padding: 4px 9px;
    line-height: 1rem;
    border-radius: 99px;
    border: 1px solid var(--clr-secondary-300);
    margin: 0;
    text-transform: capitalize;
    white-space: normal;
    position: relative;
    padding-right:1.4rem;
    &::after {
      position:absolute;
      top:3px;
      right:0.5rem;
      content: "\00d7";
      color: var(--clr-neutral-100)!important;
      font-size:1rem!important;
    }
    &:hover {
      cursor: pointer;
      border-color: var(--clr-danger);
      background-color: var(--clr-danger);
      color: var(--clr-neutral-100);;
      &:after {
        // content:'\2716';
        color: var(--clr-neutral-100);
      }
    }
  }

}
//#endregion
