@import "styles/mixins";

.nav.active {
  max-height: 100px;
  pointer-events: all;
  transition: max-height 0.3s;
  opacity: 1;
  .categories {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.active {
  background:#ece6f2;
}

.nav {
  max-height: 0px;
  background: var(--clr-neutral-125);
  z-index: 0;
  pointer-events: none;
  transition: max-height 0.3s;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navLinks {
    @include tablet() {
      display: none;
    }
  }

  .categories {
    opacity: 0;
    text-transform: capitalize;
    color: var(--clr-neutral-900);
    .slide {
      position: relative;
      width: 90px;
      white-space: nowrap;
      font-size: 0.875rem;
      flex-shrink: 0;
      a {
        padding: 0.5rem 0rem;
      }
      &:hover {
        background: #fff;
        &.active {
          background: #ece6f2;
        }
      }
   
  
      // Prevent hover effect on touch devices
      @media (hover: none) {
        &:hover {
          background: none;
          .category__title {
            color: var(--clr-neutral-800)!important; 
          }
          svg {
            color: var(--clr-primary-300)!important; 
          }
        }
      }
      @include mobile() {
        min-width: 75px;
        width: 75px;
      }
    }
    @include tablet() {
      padding-right: 15px;
    }
  }

  .category {
    display: grid;
    &__title {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-bottom: 2px solid transparent;
      padding: 4px 8px;
      color: var(--clr-neutral-800);
      font-weight: 500;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: flex-end;
    height: 40px;
    svg {
      font-size: 2.125rem;
      color: var(--clr-primary-300);
    }
  }

  .activeIcon svg {
    color: var(--clr-primary-300);
  }
}

.inactive svg {
  color: var(--clr-primary-300) !important;
}
