.bag {
  display:flex;
  flex-direction: column;
  overflow:hidden;
  background: white;
  height: 100vh;
  iframe {
    height:100%;
  }
}

.header {
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom:1rem;
}

.location {
  svg {
    color:var(--clr-primary-300);
  }
}


.title {
  font-weight:600;
  font-size:1.125rem;
  padding-bottom:1rem;
}

.buttons {
  display:flex;
  align-items: stretch;
  gap:1rem;
  margin-top:1rem;
  margin-bottom:100px;
  a, button {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius:50px;
  }
}

.total {
  display:flex;
  justify-content: flex-end;
  font-weight:400;
  font-size:0.875rem;
  span {
    font-weight:600;
    font-size:0.8755rem;
    padding-left: 0.5rem;
  }
}

.emptyCart {
  background:white;
  height:100%;
  padding:1rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding:3rem 1rem 1rem;
  button:first-of-type {
    position:absolute;
    top:0.5rem;
    left:0.5rem;
    &:hover {
      color:var(--clr-danger);
    }
  }
}