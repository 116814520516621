@import "styles/variables";
@import "styles/mixins";

.addToCart {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;

  & > div {
    justify-content: normal !important;
  }
  button {
    min-width: fit-content;
    white-space: nowrap;
    border-radius: 50px;
  }
  @include tablet() {
    & > * + * {
      flex: 1;
    }
  }
}

//#region Utility Classes

//#region sort by
.sortBy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

//#endregion

//#region column lists
.brandList,
.cityList,
.zipList {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  align-items: center;
  list-style-type: none;
  gap: 1rem;
  overflow: hidden;
  font-size: 1rem;
  margin: 1rem 0rem;
  text-transform: capitalize;

  a {
    padding-right: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.brandList {
  grid-template-columns: repeat(5, 1fr);
  @include mediumScreen() {
    grid-template-columns: repeat(4, 1fr);
  }
  @include tablet() {
    grid-template-columns: repeat(3, 1fr);
  }
  @include smallTablet() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mobile() {
    grid-template-columns: repeat(2, 1fr);
    height: 100px;
    overflow: hidden;
  }
}

.cityList {
  grid-template-columns: repeat(3, 1fr);
  @include mediumScreen() {
    grid-template-columns: repeat(3, 1fr);
  }
  @include tablet() {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mobile() {
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
  }
}

.zipList {
  grid-template-columns: repeat(8, 1fr);
  @include mediumScreen() {
    grid-template-columns: repeat(6, 1fr);
  }
  @include tablet() {
    grid-template-columns: repeat(4, 1fr);
  }
  @include mobile() {
    grid-template-columns: repeat(2, 1fr);
    height: 100px;
    overflow: hidden;
  }
}

.brandListActive,
.cityListActive,
.zipListActive {
  height: 100%;
}

//#endregion

//#region responsive layouts
// https://codepen.io/kevinpowell/pen/vYvEdWG
.cluster {
  // outline: 5px solid hotpink;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.flexible-grid {
  // outline: 5px solid hotpink;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  & > * {
    flex: 1;
  }
}

.auto-grid {
  // outline: 5px solid hotpink;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(125px, 100%), 1fr));
}

.reel {
  // outline: 5px solid hotpink;
  padding: 1rem;

  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  // grid-auto-columns: 45%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  & > * {
    scroll-snap-align: start;
  }
}

.main-with-sidebar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1em;
  max-width: 1200px;
  margin-inline: auto;
  & > :first-child {
    flex-basis: 500px;
    flex-grow: 9999;
  }
  & > :last-child {
    flex-basis: 300px;
    flex-grow: 1;
  }
}
//#endregion

.balance {
  text-wrap: balance;
}

.full-bleed {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);
}

.full-bleed-tablet {
  @include tablet() {
    box-shadow: 0 0 0 100vmax var(--bg-color);
    clip-path: inset(0 -100vmax);
  }
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
}

.icon {
  color: var(--clr-primary-300);
}

.indica {
  --color: var(--clr-indica);
}
.indica-dominant {
  --color: var(--clr-indica-dominant);
}
.hybrid {
  --color: var(--clr-hybrid);
}
.sativa-dominant {
  --color: var(--clr-sativa-dominant);
}
.sativa {
  --color: var(--clr-sativa);
}
.cbd {
  --color: var(--clr-cbd);
}

.indica,
.indica-dominant,
.hybrid,
.sativa-dominant,
.cbd,
.sativa {
  background-color: color-mix(in srgb, var(--color), transparent 95%);
  color: var(--color);
  border: 1px solid var(--color);
  &:hover {
    background-color: var(--color);
    border: 1px solid var(--color);
    color: var(--clr-neutral-100);
  }
}
.sativa {
  color: #725903;
  color: #976c05;
}

.relative {
  position: relative;
}

.link {
  color: var(--clr-primary-300);
  &:hover {
    text-decoration: underline;
    color: var(--clr-accent-300);
    cursor: pointer;
    svg {
      color: var(--clr-accent-300);
    }
  }
  &__light {
    color: var(--clr-secondary-100);
  }
}

.danger-link {
  svg {
    color: var(--clr-danger);
  }
  color: var(--clr-neutral-700);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.address {
  line-height: 1.3;
  font-size: var(--fs-400);
  white-space: nowrap;
  position: relative;
  &:hover {
    text-decoration: underline;
    color: var(--clr-accent-300);
  }
}

.copyTooltip {
  display: none;
  position: absolute;
  top: -100%;
  left: 0;
  background-color: var(--clr-primary-300);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: var(--clr-primary-300) transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
}

.address:hover .copyTooltip {
  display: block; /* Show the tooltip when hovering over the address */
  opacity: 1; /* Change opacity to make it visible */
}

.color-primary-100 {
  color: var(--clr-primary-100);
}
.color-primary-200 {
  color: var(--clr-primary-200);
}
.color-primary-300 {
  color: var(--clr-primary-300);
}
.color-primary-400 {
  color: var(--clr-primary-400);
}
.color-primary-500 {
  color: var(--clr-primary-500);
}
.color-primary-600 {
  color: var(--clr-primary-600);
}
.color-primary-700 {
  color: var(--clr-primary-700);
}
.color-primary-800 {
  color: var(--clr-primary-800);
}
.color-primary-900 {
  color: var(--clr-primary-900);
}
.color-secondary-100 {
  color: var(--clr-secondary-100);
}
.color-secondary-200 {
  color: var(--clr-secondary-200);
}
.color-secondary-300 {
  color: var(--clr-secondary-300);
}
.color-secondary-400 {
  color: var(--clr-secondary-400);
}
.color-secondary-500 {
  color: var(--clr-secondary-500);
}
.color-secondary-600 {
  color: var(--clr-secondary-600);
}
.color-secondary-700 {
  color: var(--clr-secondary-700);
}
.color-secondary-800 {
  color: var(--clr-secondary-800);
}
.color-secondary-900 {
  color: var(--clr-secondary-900);
}
.color-accent-100 {
  color: var(--clr-accent-100);
}
.color-accent-200 {
  color: var(--clr-accent-200);
}
.color-accent-300 {
  color: var(--clr-accent-300);
}
.color-accent-400 {
  color: var(--clr-accent-400);
}
.color-accent-500 {
  color: var(--clr-accent-500);
}
.color-accent-600 {
  color: var(--clr-accent-600);
}
.color-accent-700 {
  color: var(--clr-accent-700);
}
.color-accent-800 {
  color: var(--clr-accent-800);
}
.color-accent-900 {
  color: var(--clr-accent-900);
}

.color-neutral-100 {
  color: var(--clr-neutral-100);
}
.color-neutral-150 {
  color: var(--clr-neutral-150);
}
.color-neutral-175 {
  color: var(--clr-neutral-175);
}
.color-neutral-200 {
  color: var(--clr-neutral-200);
}
.color-neutral-250 {
  color: var(--clr-neutral-200);
}
.color-neutral-300 {
  color: var(--clr-neutral-300);
}
.color-neutral-400 {
  color: var(--clr-neutral-400);
}
.color-neutral-500 {
  color: var(--clr-neutral-500);
}
.color-neutral-600 {
  color: var(--clr-neutral-600);
}
.color-neutral-700 {
  color: var(--clr-neutral-700);
}
.color-neutral-800 {
  color: var(--clr-neutral-800);
}
.color-neutral-900 {
  color: var(--clr-neutral-900);
}

.text-primary-300 {
  color: var(--clr-primary-300);
}
.text-accent-300 {
  color: var(--clr-accent-300);
}
.text-secondary-300 {
  color: var(--clr-secondary-300);
}

.text-danger {
  color: var(--clr-danger);
}
.text-success {
  color: var(--clr-success);
}

.bg-primary-300 {
  background-color: var(--clr-primary-300);
  --bg-color: var(--clr-primary-300);
}
.bg-primary-100 {
  background-color: var(--clr-primary-100);
  --bg-color: var(--clr-primary-100);
}
.bg-primary-600 {
  background-color: var(--clr-primary-600);
  --bg-color: var(--clr-primary-600);
}
.bg-primary-900 {
  background-color: var(--clr-primary-900);
  --bg-color: var(--clr-primary-900);
}

.bg-neutral-100 {
  background-color: var(--clr-neutral-100);
  --bg-color: var(--clr-neutral-100);
}
.bg-neutral-125 {
  background-color: var(--clr-neutral-125);
  --bg-color: var(--clr-neutral-125);
}
.bg-neutral-150 {
  background-color: var(--clr-neutral-150);
  --bg-color: var(--clr-neutral-150);
}
.bg-neutral-175 {
  background-color: var(--clr-neutral-150);
  --bg-color: var(--clr-neutral-150);
}
.bg-neutral-200 {
  background-color: var(--clr-neutral-200);
  --bg-color: var(--clr-neutral-200);
}
.bg-neutral-250 {
  background-color: var(--clr-neutral-250);
  --bg-color: var(--clr-neutral-250);
}
.bg-neutral-300 {
  background-color: var(--clr-neutral-300);
  --bg-color: var(--clr-neutral-300);
}
.bg-neutral-400 {
  background-color: var(--clr-neutral-400);
  --bg-color: var(--clr-neutral-400);
}
.bg-neutral-500 {
  background-color: var(--clr-neutral-500);
  --bg-color: var(--clr-neutral-500);
}
.bg-neutral-600 {
  background-color: var(--clr-neutral-600);
  --bg-color: var(--clr-neutral-600);
}
.bg-neutral-700 {
  background-color: var(--clr-neutral-700);
  --bg-color: var(--clr-neutral-700);
}
.bg-neutral-800 {
  background-color: var(--clr-neutral-800);
  --bg-color: var(--clr-neutral-800);
}
.bg-neutral-900 {
  background-color: var(--clr-neutral-900);
  --bg-color: var(--clr-neutral-900);
}

.italic {
  font-style: italic;
}

.fw-regular {
  font-weight: var(--fw-regular);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-bold {
  font-weight: var(--fw-semi-bold);
}

.fw-100 {
  font-weight: 300;
}
.fw-200 {
  font-weight: 300;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.fs-100 {
  font-size: var(--fs-100);
}
.fs-200 {
  font-size: var(--fs-200);
}
.fs-300 {
  font-size: var(--fs-300);
}
.fs-400 {
  font-size: var(--fs-400);
}
.fs-500 {
  font-size: var(--fs-500);
}
.fs-600 {
  font-size: var(--fs-600);
}
.fs-700 {
  font-size: var(--fs-700);
}
.fs-800 {
  font-size: var(--fs-800);
}
.fs-900 {
  font-size: var(--fs-900);
}
.fs-1000 {
  font-size: var(--fs-1000);
}
.fs-1100 {
  font-size: var(--fs-1100);
}
.fs-1200 {
  font-size: var(--fs-1200);
}
.fs-1300 {
  font-size: var(--fs-1300);
}

.p-0 {
  padding: 0.5em;
}
.p-1 {
  padding: 1em;
}
.p-2 {
  padding: 2em;
}
.p-3 {
  padding: 2.5em;
}
.p-4 {
  padding: 3em;
}

.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.pb-1 {
  padding-bottom: 0.5em;
}
.pb-2 {
  padding-bottom: 1em;
}
.pb-3 {
  padding-bottom: 2em;
}
.pb-4 {
  padding-bottom: 3em;
}

.pt-1 {
  padding-top: 0.5em;
}
.pt-2 {
  padding-top: 1em;
}
.pt-3 {
  padding-top: 2em;
}
.pt-4 {
  padding-top: 3em;
}

.pl-1 {
  padding-left: 0.5em;
}
.pl-2 {
  padding-left: 1em;
}
.pl-3 {
  padding-left: 2em;
}

.pr-1 {
  padding-right: 0.5em;
}
.pr-2 {
  padding-right: 1em;
}
.pr-3 {
  padding-right: 2em;
}

.p-25 {
  padding: 0.25em;
}
.p-50 {
  padding: 0.5em;
}
.p-100 {
  padding: 1em;
}
.p-150 {
  padding: 1.5em;
}
.p-200 {
  padding: 2em;
}
.p-250 {
  padding: 2.5em;
}
.p-300 {
  padding: 3em;
}
.p-400 {
  padding: 4em;
}

.mb-25 {
  margin-bottom: 0.25em;
}
.mb-100 {
  margin-bottom: 1em;
}
.mb-200 {
  margin-bottom: 2em;
}
.mb-300 {
  margin-bottom: 3em;
}
.mb-400 {
  margin-bottom: 4em;
}

.mt-25 {
  margin-top: 0.25em;
}
.mt-100 {
  margin-top: 1em;
}
.mt-200 {
  margin-top: 2em;
}
.mt-300 {
  margin-top: 3em;
}
.mt-400 {
  margin-top: 4em;
}
.mr-25 {
  margin-right: 0.25em;
}
.mr-100 {
  margin-right: 1em;
}
.mr-200 {
  margin-right: 2em;
}
.mr-300 {
  margin-right: 3em;
}
.mr-400 {
  margin-right: 4em;
}

.ml-25 {
  margin-left: 0.25em;
}
.ml-100 {
  margin-left: 1em;
}
.ml-200 {
  margin-left: 2em;
}
.ml-300 {
  margin-left: 3em;
}
.ml-400 {
  margin-left: 4em;
}

.pb-25 {
  padding-bottom: 0.25em;
}
.pb-50 {
  padding-bottom: 0.5em;
}
.pb-100 {
  padding-bottom: 1em;
}
.pb-200 {
  padding-bottom: 2em;
}
.pb-300 {
  padding-bottom: 3em;
}
.pb-400 {
  padding-bottom: 4em;
}

.pt {
  padding-top: 5px;
}
.pt-25 {
  padding-top: 0.25em;
}
.pt-50 {
  padding-top: 0.5em;
}
.pt-100 {
  padding-top: 1em;
}
.pt-200 {
  padding-top: 2em;
}
.pt-300 {
  padding-top: 3em;
}
.pt-400 {
  padding-top: 4em;
}

.pl-25 {
  padding-left: 0.25em;
}
.pl-50 {
  padding-left: 0.5em;
}
.pl-100 {
  padding-left: 1em;
}
.pl-200 {
  padding-left: 2em;
}
.pl-400 {
  padding-left: 4em;
}

.pr-25 {
  padding-right: 0.25em;
}
.pr-50 {
  padding-right: 0.5em;
}
.pr-100 {
  padding-right: 1em;
}
.pr-200 {
  padding-right: 2em;
}
.pr-300 {
  padding-right: 3em;
}
.pr-400 {
  padding-right: 4em;
}

.gap {
  gap: 5px;
}
.gap-50 {
  gap: 0.5rem;
}
.gap-100 {
  gap: 1rem;
}
.gap-150 {
  gap: 1.5rem;
}
.gap-200 {
  gap: 2rem;
}
.gap-300 {
  gap: 3rem;
}
.gap-400 {
  gap: 4rem;
}
.gap-500 {
  gap: 5rem;
}
.gap-600 {
  gap: 6rem;
}

.h-100 {
  height: 100%;
}

.br-300 {
  border-radius: var(--border-radius-md);
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.odd-columns {
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(3, 1fr);
  @include tablet() {
    grid-template-columns: 1fr;
  }
}
.even-columns-2 {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 500px), 1fr));
  align-items: center;
  @include smallTablet() {
    grid-template-columns: 1fr;
  }
}
.even-columns-4 {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  @include mediumScreen() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include smallTablet() {
    grid-template-columns: 1fr;
  }
}

// @media (min-width: 50em) {
//   .even-columns {
//     grid-auto-flow: column;
//     grid-auto-columns: 1fr;
//   }
// }

.col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  gap: 1em;
  @include tablet() {
    grid-template-columns: 1fr;
  }
}
.col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  gap: 1em;
  @include tablet() {
    grid-template-columns: 1fr;
  }
}

.flex-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  @include tablet() {
    flex-direction: column;
  }
  div {
    flex: 1 1 50%;
  }
}

.tablet-reverse {
  @include tablet() {
    flex-direction: column-reverse;
  }
}

.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}

.center {
  justify-content: center;
}
.justify-content-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-items-center {
  justify-items: center;
}

.border-bottom {
  border-bottom: 1px solid var(--clr-neutral-250);
}

.flex-start {
  justify-content: flex-start;
}

.mobile-hide {
  @include mobile() {
    display: none;
  }
}
.tablet-hide {
  @include tablet() {
    display: none;
  }
}

//#endregion

//#region General Styling

html,
body {
  min-width: 100%;
  min-height: 100%;
  // scroll-behavior: smooth !important;
}

body {
  width: 100%;
  font-size: var(--fs-body);
  color: var(--clr-neutral-850);
  background-color: var(--clr-neutral-900);
  overflow: unset;
  height: auto;
  -webkit-text-size-adjust: 100%;
}

.modal-open {
  overflow: hidden;
  height: 100%;
}

#__next {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--clr-neutral-125);
}

strong {
  font-weight: bold;
}
em {
  font-style: italic;
}

.content-wrap {
  padding-top: 61px;
  margin-bottom: auto;
  // @include mobile() {
  //   padding-top: 187px;
  // }
}
.content-wrap-slim {
  padding-top: 60px;
  margin-bottom: auto;
  // @include mobile() {
  //   padding-top: 100px;
  // }
}

a {
  color: inherit;
  text-decoration: none;
  overflow-wrap: break-word;
}

p {
  padding-bottom: 1em;
  // font-size: 0.875rem;
  font-size: 0.9375rem;
  a {
    color: var(--clr-primary-300);
    text-decoration: underline;
  }
}

i {
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  // text-transform: capitalize;
}

h1,
h2,
h3 {
  text-wrap: balance;
}

h1 {
  font-size: var(--fs-900);
}
h2 {
  font-size: var(--fs-800);
  // line-height:2.125rem;
}

.text ul {
  padding-inline-start: 26px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-size: 0.875rem;
}

input {
  accent-color: var(--clr-primary-300);
}

.container {
  --max-width: 1200px;
  --padding: 1rem;

  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
  height: 100%;
  &__slim {
    @include tablet() {
      --padding: 0.5rem;
    }
  }
}

.row {
  padding: 4em 0em;
}
.row-slim {
  padding: 2em 0em;
}

.container-row {
  padding-top: 2rem;
  padding-bottom: 2rem;
  --max-width: 1200px;
  --padding: 0rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
  &:last-of-type {
    padding-bottom: 2em;
  }
  &__inner {
    padding-inline: 1rem;
  }
  h3 {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }
}

button:hover {
  cursor: pointer;
}

.button {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 0.5em 0.9em;
  border-radius:50px;
  // font-weight: 500;
  border: 1px solid;
  line-height: 1.4rem;
  &:hover {
    cursor: pointer;
  }
}

.button[data-type="primary"] {
  background: var(--clr-primary-300);
  border-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  &:hover {
    background: var(--clr-secondary-300);
    border-color: var(--clr-secondary-300);
  }
}

.button[data-type="basic"] {
  background: var(--clr-neutral-250);
  border-color: var(--clr-neutral-250);
  color: var(--clr-neutral-800);
  &:hover {
    background: var(--clr-secondary-300);
    border-color: var(--clr-secondary-300);
    color: var(--clr-neutral-100);
    svg {
      color: var(--clr-neutral-100);
    }
  }
}

.button[data-type="text"] {
  background: none;
  border: none;
  outline: none;
  padding: 0px;
}

button[data-type="close"] {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--clr-neutral-800);
  padding: 0px;
  &:hover {
    cursor: pointer;
    color: var(--clr-danger);
  }
}

.card[data-type="contact"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-neutral-100);
  padding: 2rem;
  text-align: center;
  border: var(--border);
  h3 {
    font-size: var(--fs-500);
  }
  svg {
    color: var(--clr-primary-300);
    font-size: 2rem;
    padding-bottom: 0.5rem;
  }
  @include mobile {
    padding: 1rem;
  }
}

.w-100 {
  width: 100% !important;
}
.page-header {
  text-align: center;
  padding: 5em 0em;
}

.section-title {
  text-align: center;
  font-weight: 600;
  padding: 2em 0em;
}

/* ===== Scrollbar ===== */
/* Firefox */
// * {
//   scrollbar-width: auto;
//   scrollbar-color: hsl(0, 0%, 75%) hsl(0, 0%, 90%);
// }
// // /* Chrome, Edge, and Safari */
// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-track {
//   background: hsl(0, 0%, 90%);
// }

// ::-webkit-scrollbar-thumb {
//   background-color: hsl(0, 0%, 75%);
//   border: 0px solid transparent;
// }

.disable-animations * {
  animation: none !important;
  transition: none !important;
}

.showMore {
  text-decoration: underline;
  font-size: 0.875rem;
  &:hover {
    cursor: pointer;
    color: var(--clr-primary-300);
  }
}

//#endregion

//#region 404 page
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;
  @include tablet() {
    // min-height:auto;
  }
}
//#endregion

//#region rheostat
.rheostat-range-slider {
  display: flex;
  flex-direction: column;
  gap: 0px;
  max-width: 90%;
  margin: 1rem auto 0rem auto;
  padding-right: 25px;
  @include tablet() {
    margin: 2rem auto;
  }
}
.rheostat-range-slider .title {
  display: flex;
  justify-content: space-between;
}
.rheostat {
  pointer-events: none;
}
.rheostat button {
  pointer-events: all;
}
.rheostat button:hover {
  cursor: pointer;
}

.DefaultProgressBar_progressBar {
  background-color: #663d99;
  position: absolute;
}

.DefaultProgressBar_background__horizontal {
  height: 5px;
  top: 0px;
}
.DefaultHandle_handle {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-style: solid;
  border-color: #663d99;
  background-color: white;
  border-radius: 100%;
  outline: none;
  z-index: 2;
}
.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px;
}

.DefaultHandle_handle__horizontal {
  top: -6px;
  margin-left: 0px;
}
.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 10px;
}
.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 13px;
}

.DefaultBackground {
  background-color: rgb(243, 243, 243);
  height: 5px;
  width: 100%;
  position: relative;
}
.DefaultBackground_background__horizontal {
  height: 5px;
  width: 100%;
}

.rheostat {
  position: relative;
  overflow: visible;
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}

.handleContainer {
  height: 10px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  /* position: absolute; */
}

.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}
.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}

.rheostat-range {
  font-size: 0.875rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

//#endregion

//#region home/about

.blog {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  gap: 1em;
  padding-bottom: 1em;
  flex-wrap: nowrap;
  overflow: auto;
  a {
    min-width: 300px;
  }
  @include mobile() {
    a {
      max-width: 100vh;
    }
  }
}

.blog-2 {
  grid-template-columns: repeat(2, 1fr);
  @include smallTablet() {
    grid-template-columns: 1fr;
  }
  a {
    min-width: unset;
  }
}

.blog-3 {
  grid-template-columns: repeat(3, 1fr);
}

.blog-4 {
  display: flex;
  a {
    flex: 1 1 0;
    &:last-of-type {
      flex: 0 0 15rem;
    }
  }
}

.blog-end {
  background: var(--clr-primary-300);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem;
  color: white;
  height: 100%;
  // width: 100%;

  border: 1px solid var(--clr-neutral-250);
  transition: box-shadow 0.2s ease;
  border-radius: var(--border-radius);

  &:hover {
    box-shadow: var(--box-shadow);
    background-color: var(--clr-primary-400);
    text-decoration: underline;
  }
}

.community-logos {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // max-width:1200px;
  margin: auto;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
  }
  svg {
    width: 150px;
    height: 75%;
  }
  @include mediumScreen() {
    svg {
      width: 150px;
    }
  }
  @include smallTablet() {
    svg {
      width: 125px;
    }
  }
}

//#endregion

//#region search box
.searchBox {
  width: 100%;
  font-size: inherit;
  position: relative;
  input {
    width: 100%;
    border: 1px solid var(--clr-neutral-300);
    border-radius: 50px;
    padding: 0.5rem 1rem;
    padding-left: 2.5rem;
    outline: none;
  }
  &::before {
    background: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
      repeat scroll 0 0;
    content: "";
    height: 1rem;
    left: 1rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
  }
}

.refinementSearchBox {
  width: 100%;
  font-size: inherit;
  position: relative;
  font-size: 0.875rem;
  input {
    width: 100%;
    border: 1px solid var(--clr-neutral-300);
    border-radius: 50px;
    padding: 5px 0px;
    padding-left: 2.1rem;
    outline: none;
  }
  &::before {
    background: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
      repeat scroll 0 0;
    content: "";
    height: 1rem;
    left: 0.75rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
  }
  @include tablet() {
    font-size: 1rem;
  }
}
//#endregion

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

//#region Card todo: replace this card stuff with a separate card component. Perhaps switch cards and slides
.location-card-container {
  > div {
    height: unset;
  }
}

.applicable-bundle-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--border-radius-md);
  width: 120px;
  height: 120px;
  img {
    background: white;
    border-radius: var(--border-radius-md);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bundle-header {
  display: flex;
  flex-direction: row;
  height: fit-content;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: none;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius-md);
  img {
    object-fit: cover;
  }
  @include tablet() {
    flex-direction: column;
  }
}

.bundle-body {
  padding: 2rem;
  width: 100%;
}

.bundle-fallback-tag {
  background-color: var(--clr-primary-300);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.75rem;
  text-transform: capitalize;
  @include tablet() {
    max-width: 100%;
  }
  svg {
    font-size: 2.125rem;
  }
}

.bundle-delete {
  position: absolute;
  top: -5px;
  right: -5px;
  background: white;
  color: var(--clr-neutral-350);
  border: 1px solid var(--clr-neutral-300);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 1rem; 
  box-shadow: var(--box-shadow);
  &:hover {
    color: var(--clr-danger);
  }
}

.bundle-reel {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  /* Removed justify-content: center; */
  &::before,
  &::after {
    content: "";
    width: 50%;
    height: 1px;
    grid-row: 1;
  }
  & > * {
    scroll-snap-align: start;
  }
}

.bundle-card {
  background: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius-md);
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 8px;
  &__image {
    height: 200px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
  }
}

.bundle-added-products {
  --bg-color: var(--clr-neutral-175);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-md);
  padding: 3rem;
  margin-bottom: 2rem;
  @include tablet() {
    padding-inline: 0rem;
  }
}

.bundle-success {
  background: var(--clr-neutral-100);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  gap: 1rem;
  margin-top: 1rem;
  @include tablet() {
    padding: 1rem;
  }
}

.text-ellipsis {
  overflow: hidden; // Prevents the text from overflowing the container's bounds
  white-space: nowrap; // Keeps the text on a single line
  text-overflow: ellipsis; // Adds an ellipsis (...) at the end of the truncated text
}

.pickup {
  align-items: flex-start;
  & iframe {
    flex: 1 1 70%;
  }
}

//#endregion

//#region our brands
.our-brands {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  a {
    max-width: 900px;
    margin: auto;
  }
  > div {
    background: transparent;
    color: var(--clr-neutral-150);
    border-color: transparent;
    padding: 0px;
  }
  div > * {
    text-align: left !important;
    align-items: flex-start !important;
  }
  img {
    max-width: 300px;
  }
}
//#endregion

//#region promos-grid
.promos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  overflow-x: scroll;
  padding: 1rem !important;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
//#endregion

//#region rewards page
.rewards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @include mediumScreen() {
    grid-template-columns: 1fr;
  }
  .reward {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    border-radius: var(--border-radius);
    padding: 2rem;
    gap: 0rem;
    @include mobile() {
      padding: 1rem;
    }
  }
  .reward[data-type="primary"] {
    background: var(--clr-primary-300);
    color: white;
    border-color: transparent;
    a {
      color: white;
    }
  }
  .reward[data-type="light"] {
    background: var(--clr-primary-50);
    color: var(--clr-primary-800);
    border-color: transparent;
    a {
      color: white;
    }
  }
  > div {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .orders {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    > div {
      display: flex;
      flex-direction: column;
      min-width: 20ch;
      flex: 1 1 calc(50% - 0.5rem);
      background-color: var(--clr-neutral-100);
      border: 1px dashed var(--clr-neutral-300);
      padding: 1rem;
      font-size: 0.9375rem;
      > span:first-of-type {
        color: var(--clr-primary-300);
        font-weight: bold;
        font-size: 1rem;
        padding-bottom: 5px;
      }
      sup {
        font-size: var(--fs-200);
      }
    }
    ul {
      margin: 0;
    }
  }
  .buds-for-buds {
    div {
      gap: 1rem;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--clr-primary-300);
      padding: 0.5rem;
      font-weight: 600;
      text-align: center;
      font-size: 1rem;
      border: 1px dashed var(--clr-primary-100);
    }
  }
}
//#endregion

//#region  pagination
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 5rem;
  gap: 5px;
  list-style-type: none;
  font-size: 1.5rem;
  button {
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    &:hover {
      cursor: pointer;
      background-color: var(--clr-primary-300);
      color: var(--clr-neutral-100);
      svg {
        color: var(--clr-neutral-100);
      }
    }
    svg {
      color: var(--clr-neutral-800);
    }
  }

  .active {
    background-color: var(--clr-primary-300);
    color: var(--clr-neutral-100);
    svg {
      color: var(--clr-neutral-100);
    }
  }
  .pageInput {
    input {
      max-width: 80px;
      background: white;
      border: 1px solid var(--clr-neutral-300);
      padding: 1rem;
      width: fit-content;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
//#endregion

.whyPurpleLotus {
  position: relative;
  overflow: hidden;
  h2 {
    color: rgb(238, 238, 238) !important;
    font-size: clamp(50px, 6vw, 6rem);
    line-height: clamp(55px, 6.5vw, 6.5rem);
  }
}

.ourBrands {
  color: white;
  h2 {
    font-size: 2.625rem;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 2px solid transparent;
    @include mobile() {
      font-size: 1.675rem;
    }
  }
}

.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
}

.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
}

//#region  lottie
.lottie {
  .clr-1 path {
    // stroke: #9168ff;
    stroke: var(--clr-primary-200);
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path,
  .clr-2 {
    // stroke: rgb(190, 190, 190);
    stroke: rgb(60, 60, 60);
  }
}

.lottie-hover {
  .clr-1 path {
    stroke: var(--clr-secondary-300);
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path,
  .clr-2 {
    stroke: rgb(60, 60, 60);
  }
}

.lottie-dark {
  .clr-1 path {
    stroke: var(--clr-primary-300);
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path,
  .clr-2 {
    stroke: rgb(38, 38, 38);
  }
}

.lottie-dark-hover {
  .clr-1 path {
    stroke: var(--clr-secondary-300);
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path,
  .clr-2 {
    stroke: rgb(55, 55, 55);
  }
}

.lottie-strain {
  width: 75px;
  .clr-1 path {
    stroke: red;
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path {
    stroke: black;
  }
}
.lottie-strain-hover {
  width: 75px;
  .clr-1 path {
    stroke: cyan;
    transition: stroke 0.5s ease-in-out;
  }
  .clr-2 path {
    stroke: black;
  }
}

//#endregion

.hours {
  display: flex;
  flex-direction: column;
  // gap: 5px;
  .today span {
    color: var(--clr-accent-300) !important;
  }
  .day {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-weight: 400;
    color: var(--clr-neutral-700);
    padding-bottom:3px;
    span {
      font-size: 0.875rem;
      color: var(--clr-neutral-800);
      font-weight: 600;
    }
  }
}

//#region FAQ
.faq {
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius);
  background: var(--clr-neutral-100);
  padding: 2rem;
  margin-bottom:2rem;
  @include tablet() {
    padding: 1rem;
  }
}

.faq-page {
  display: flex;
  gap: 3%;
  position: relative;
  @include tablet() {
    flex-direction: column;
  }
}

.faq-categories {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: sticky;
  top: 100px;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius);
  background: var(--clr-neutral-100);
  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0rem;
    font-weight: 500;
    // border-bottom: 1px solid var(--clr-neutral-300);
    &:hover {
      background-color: var(--clr-neutral-100);
      color: var(--clr-primary-300);
    }
  }
  @include tablet() {
    position: unset;
    margin-bottom: 2rem;
  }
}
//#endregion

//#region careers page
.careers-image-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 500px;
  img {
    object-fit: cover;
  }
  @include tablet() {
    min-height: 300px;
  }
}
//#endregion

//#region site-map
.site-map {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
}
.site-map-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius);
  background: var(--clr-neutral-100);
  padding: 2rem;
  flex: 1;
  // width:500px;
  min-width: 250px;
  ul {
    list-style-type: none;
    li {
      padding-bottom: 5px;
    }
    a {
      text-transform: capitalize;
      color: var(--clr-neutral-800);
      &:hover {
        color: var(--clr-primary-300);
      }
    }
  }
}
//#endregion

.broadly-reviews {
  max-width: 900px;
  margin: auto;
  .broadly-review-aggregate {
    display: flex;
    justify-content: center;
    gap: 1rem;
    span[role="img"] {
      display: flex;
    }
  }
  span {
    color: var(--clr-primary-300);
  }
  image {
    filter: hue-rotate(213deg) saturate(10);
  }
  .broadly-review-heading {
    display: flex;
    gap: 1rem;
  }
  .broadly-review-rating span {
    display: flex;
  }
}

//#region leaflet
.lotus-location-icon {
  filter: drop-shadow(11px 12px 18px rgba(0, 0, 0, 0.57));
  -webkit-filter: drop-shadow(11px 12px 18px rgba(0, 0, 0, 0.57));
  -moz-filter: drop-shadow(11px 12px 18px rgba(0, 0, 0, 0.57));
}

.leaflet-top,
.leaflet-bottom {
  z-index: 400 !important;
}
.leaflet-container:after {
  z-index: unset !important;
}
//#endregion

.bundle-grid .ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 215px), 1fr));
  margin-inline: auto;
  grid-gap: 1rem;
  gap: 1rem;
}

// contact us
.contact-us {
  display: flex;
  // flex-wrap: wrap;
  gap: 2rem;

  > div:first-child {
    flex: 1 1 400px;
  }

  .contact-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1 1 50%; // Takes up the remaining 50% of the space in large screens

    > a.card,
    > .card {
      flex-grow: 1;
      min-width: 250px; // Items will wrap if they can't shrink below 250px
      &:last-child {
        flex: 0 0 100%; // This makes the last item take full width when alone on a row
      }
      @include mobile() {
        min-width: unset !important;
        flex: 100%;
      }
    }
  }

  @include tablet {
    flex-direction: column;

    > div:first-child,
    .contact-grid {
      flex: 0 0 100%; // Each child will take full width on tablet size
    }
  }
}

.no-scroll {
  width: 100%;
}

.new-location-tag:after {
  content: "NEW";
  font-size: 0.75rem;
  background: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  padding: 0px 10px;
  border-radius: 3px;
  position: absolute;
  top: -10px;
  right: 0px;
}

.parking {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  column-gap: 3rem;
  row-gap: 1rem;
  // font-size: 0.875rem;
  // font-weight: 500;
  color: var(--clr-neutral-800);
}

.cartTotalSpecials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.75rem;
  gap: 1.75rem;
  margin-top: 2em;
  &__special-body {
    padding: 1rem;
    > div {
      color: var(--clr-neutral-900);
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  > div {
    background: var(--clr-neutral-100);
    border: 1px solid var(--clr-neutral-250);
    border-radius: var(--border-radius-md);
    height: 100%;
    overflow: hidden;
  }

  .image {
    position: relative;
    height: 200px;
  }
}

.fc .fc-daygrid-event {
  margin-top: 1.5px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  padding-right: 12px;
}

.deals-toggle {
  display: flex;
  gap: 0rem;
  justify-content: flex-end;
  @include mobile() {
    justify-content: flex-start;
  }
}

.fullscreen {
  width: 100%!important;
}

.minimized {
  width: 50%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 999;
  overflow-y: auto;
}


.error-message, .error {
  color:var(--clr-danger);
}
.success-message, .success {
  color:var(--clr-success);
}


.iconBrowser {
  h1 {
    padding-bottom:1rem;
    span {
      text-transform: none;
      font-size:1rem;
      font-weight:500;
      color:#777;
    }
  }
  .title {
    display:flex;
    flex-direction: column;
    text-transform: capitalize;
    padding-bottom:1rem;
    h2 {
      display:flex;
      align-items: center;
      gap:0.5rem;
      span {
        text-transform: none;
        font-size:1rem;
        font-weight:500;
      }
    }
    span {
      text-transform: none;
      font-size:14px;
      color:#777;
      font-weight:500;
    }
  }
  .icon-grid {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap:1rem;
    padding-bottom:2rem;
  }
  .icon {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
    color:unset;
    padding:1rem;
    border-radius:var(--border-radius);
    border:1px solid rgb(201, 201, 201);
    font-size:0.875rem;
    svg {
      font-size:2rem;
      max-height:50px;
    }
    &:hover {
      background:var(--clr-neutral-125);
      border-color:var(--clr-primary-300);
    }
  }
}