.toggle:not(:last-of-type) {
  border-bottom: 1px solid var(--clr-neutral-200);
}

.toggle {
  user-select: none;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em 0em;
    &:hover {
      cursor: pointer;
    }
    .icon {
      margin:0em 1em;
    }
    .title {
      font-weight: 600;
      // font-size:var(--fs-400);
    }
    .tag {
      background:var(--clr-specials);
      padding: 2px 12px;
      font-size:0.75rem;
      border-radius: 50px;
      text-transform: uppercase;
      font-weight:  600;
    }
  }

  &__content {
    overflow:hidden;
  }
}

.hidden {
  display:none
}

.active {
  display:block;
}