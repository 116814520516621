@import "styles/mixins";

.popup {
  position: absolute;
  width: 420px;
  top: 60px;
  right: 0;
  z-index: 500;
  @include tablet() {
    top: 0px;
    width: 100%;
  }
  &__title {
    color: var(--clr-primary-300);
    font-weight: 600;
    font-size: 1rem;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875em;
    margin: auto;
    width: 100%;
    max-width: 500px;
    padding: 0em 1em 1.5em 1em;
    margin: 0;
    background: var(--clr-neutral-100);
    border-top: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    z-index: 3;
    max-height: 100vh;
    overflow: auto;

    @include tablet() {
      width: 100vw;
      margin-top: 0;
      position: fixed;
      left: 50%;
      top: 0px;
      transform: translate(-50%, 0%);
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
      border: 0px;
      // border-bottom:3px solid var(--clr-primary-300);
    }
    @include mobile {
      max-width: unset;
    }
  }
  &__header {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__close {
    background: none;
    border: none;
    svg {
      color: var(--clr-neutral-850);
    }
    span {
      display: none;
    }
    &:hover {
      cursor: pointer;
      color: var(--clr-danger);
    }
  }

  .tabs {
    display: flex;
    width: 100%;
  }

  .tab {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.8em;
    border: 1px solid transparent;
    color: var(--clr-neutral-800);
    border: var(--locationpopup-tabs-border);
    // opacity:0.5;
    svg {
      max-height: 50px;
    }
    &__content {
      display: none;
      width: 100%;
    }
    &:first-of-type {
      border-radius: var(--border-radius) 0px 0px var(--border-radius);
    }
    &:nth-of-type(2) {
      border-radius: 0px var(--border-radius) var(--border-radius) 0px;
    }
    @include mobile() {
      padding: 5px;
    }
  }

  .active {
    background: var(--clr-neutral-100);
    border: 1px solid var(--clr-primary-100);
    opacity: 1;
    &__content {
      display: block;
    }
    span {
      font-weight: 500;
    }
  }

  //delivery tab
  .delivery_locations {
    display: flex;
    flex-direction: column;
    gap: 1em;
    a {
      text-align: center;
      width: fit-content;
    }
    input {
      padding: 0.8em;
      border: var(--locationpopup-tabs-border);
    }
    .delivery_input {
      display: flex;
      flex-direction: column;
      gap: 0.3em;
      a {
        align-self: flex-end;
      }
    }
  }

  // pickup tab
  .pickup_location {
    cursor: pointer;
    display: flex;
    margin: 0em auto 0.875em;
    border: 1px solid var(--clr-neutral-300);
    border-radius: var(--border-radius);
    padding: 0.875rem;

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none; 
      -moz-appearance: none; 
      width: 16px;
      height: 16px;
      border: 1px solid var(--clr-neutral-400); 
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
      position: relative;
      display: inline-block;
    }
    
    input[type='radio']:checked {
      background-color: #fff; 
      border-color: var(--clr-accent-300); 
    }
    
    input[type='radio']:checked::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: var(--clr-accent-300); /* Inner circle color */
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    
    input[type='radio']:hover,
    input[type='radio']:focus {
      outline: none; /* Remove focus outline */
      box-shadow: none; /* Remove any hover shadow effects */
    }
    button {
      width: fit-content;
    }
  }
}

.store {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  align-items: center;
}

.selected {
  // background: var(--clr-neutral-600) !important;
  border-color: var(--clr-accent-300) !important;
  border-width: 1px !important;
}

.type {
  opacity: 0.8;
  font-weight: var(--fw-regular);
}

.pickup_locations_address {
  display: flex;
  flex-direction: column;
  font-weight: 300;
}

.backdrop {
  // opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  // transition: opacity 0.2s;
  z-index: 500;
  pointer-events: all;
  backdrop-filter: blur(5px);
  // transition: backdrop-filter 0s, opacity 0.3s;
  @include tablet() {
    top: 0px;
  }
}

.popupOpen {
  // transition: backdrop-filter 0s, opacity 0.3s;
  .popup {
    opacity: 1;
    pointer-events: all;
  }
  .backdrop {
    opacity: 1;
    pointer-events: all;
    // transition: backdrop-filter 0s, opacity 0.3s;
  }
}
.popupClosed {
  .popup {
    opacity: 0;
    pointer-events: none;
  }
  .backdrop {
    opacity: 0;
    pointer-events: none;
    // transition: backdrop-filter 0s, opacity 0.3s;
  }
}

.storeHeader {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.address {
  opacity: 1;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 0.875rem;
  gap: 3px;
  svg {
    font-size: 0.875rem;
    color: var(--clr-primary-300);
  }
}
.openEveryday {
  color: var(--clr-neutral-700);
  line-height: 16px;
  padding-bottom: 5px;
  text-align: right;
  font-size: 0.875rem;
}

.new:before {
  content: "NEW";
  font-size: 0.625rem;
  background: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  padding: 2px 6px;
  border-radius: 3px;
}
.new {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 0.75rem;
  color: var(--clr-accent-300);
  border-radius: 3px;
}

.skeleton {
  gap: 0.75rem;
  width: 100%;
  flex-wrap: wrap;
  padding:5px;
  > div:last-of-type {
    margin:0px;
  }
}

.storeTitle {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  white-space: nowrap;
  @include mobile() {
    font-size: 15px;
  }
}

.open {
  color: var(--clr-secondary-300);
  font-weight: 500;
}
.closed {
  color: var(--clr-danger);
}

.hours {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 0.875rem;
  flex: 1 1;
  column-gap: 5px;
  white-space: nowrap;
  justify-content: flex-end;
}

.warning {
  color: var(--clr-danger);
  font-size: bold;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}