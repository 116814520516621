@import "styles/mixins";

// =====================
// Product Card Styles
// =====================
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 8px;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: var(--box-shadow);
  }

  @include tablet() {
    &:hover {
      box-shadow: none;
      border: 1px solid var(--clr-neutral-250);
    }
  }

  .image_container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 250px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    img {
      object-fit: cover;
      border-radius: inherit;
    }

    @include mobile() {
      height: 200px;
    }
  }

  .body {
    display: grid;
    width: 100%;
    padding: 1rem 1rem 0.75rem;
    gap: 9px;
    align-content: center;

    @include mobile() {
      padding: 1rem 0.5rem 0.5rem;
    }

    .name {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      max-width: 100%;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        color: var(--clr-accent-300);
      }

      @include mobile() {
        font-size: 0.875em;
      }
    }

    .kind {
      height: 18px;
      padding: 0 8px;
      border-radius: var(--border-radius-sm);
      background: var(--clr-neutral-700);
      color: var(--clr-neutral-100);
    }
  }

  .product-type {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
}

.favorite {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  font-size: 1.5rem;
}

.saleTag {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
}

.description {
  display: none;
}

// =====================
// Product Info & Footer
// =====================
.product__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: capitalize;
  overflow: hidden;

  @include mobile() {
    font-size: 0.688rem;
  }
}

.product__footer {
  margin-top: auto;
}

.subtype {
  padding: 0 5px;
  font-size: 0.75rem;
  font-weight: normal;
  border-radius: var(--border-radius-sm);
  background: var(--clr-neutral-250);
  color: var(--clr-neutral-700);
}

.priceAndBag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dosages {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}

// =====================
// Ratings
// =====================
.rating {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 1px 0;

  .star {
    width: 15px;
    height: 15px;
  }

  .filled {
    color: #ffc220;
  }

  .empty {
    color: #dadada;
  }

  .reviewText {
    margin-left: 3px;
    font-size: 0.75rem;
    color: var(--clr-neutral-500);
    line-height: normal;
    white-space: nowrap;
  }

  .count::after {
    content: " reviews";
    @include mobile() {
      content: "";
    }
  }
}

// =====================
// Product List Styles
// =====================
.list {
  padding-inline: 1rem;

  @include tablet() {
    padding-inline: 0;
  }

  > div > ol {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .product {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
      column-gap: 1rem;
      row-gap: 0.5rem;
      text-wrap: nowrap;
      flex-wrap: wrap;
      grid-column: auto / span 2;
      width: 100%;
      margin-top: 0;

      > div > div > div {
          justify-content: flex-end;
          column-gap:1rem;
      }
      
      > div {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: flex-end;
        width: 100%;
      }

      button {
        padding-inline: 1rem;
        border-radius: var(--border-radius-md);
      }
    }

    > a {
      min-width: 100px;

      @include smallMobile() {
        min-width: 60px;
      }
    }

    .saleTag {
      position: unset;
      display: flex;
      align-items: center;
      font-size: 0.75rem;

      svg {
        font-size: 1rem;
        padding-right: 5px;
        display: block;
      }
    }

    .product__info {
      flex-wrap: wrap;
    }
  }

  .body {
    flex-wrap: wrap;
    width: fit-content;
    padding: 0;
  }

  .add_to_bag {
    margin-top: 0;
  }

  .image_container {
    flex: 0 0 auto;
    width: clamp(80px, 20vw, 180px); // min 100px, scales up to 150px
    height: auto;
    aspect-ratio: 1;
    margin: 0;
    border-radius: var(--border-radius-md);
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      border-radius: var(--border-radius-md);
      object-fit: contain !important;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    display: flex;
    max-width: 500px;

    @include mediumScreen() {
      display: none;
    }
  }

  // =====================
  // list mobile Layout
  // =====================
  @include mobile() {
    padding-inline: 0.5rem;

    .product {
      display: grid;
      grid-template-columns: auto 1.5fr;
      grid-template-areas:
        "image body"
        "button button";
      gap: 1rem;
      row-gap: 0.5rem;
      padding: 1rem 0.5em;
      border: none;
      border-bottom: 1px solid var(--clr-neutral-250);
      border-radius: 0;
      &__footer > div > div {
        width: 100%; // to make the button take the full width
      }
    }

    .options {
      grid-area: button;
      margin: 0;
      padding: 0;

      > * {
        justify-content: flex-start;
      }

      span {
        margin: unset;
      }
    }

    .body {
      grid-area: body;
      width: 100%;
      text-align: left;
      flex-direction: column;
      margin: 0;
      padding: 0;
    }

    

    .buttonContainer {
      justify-content: flex-start;
      margin-top: 0.5rem;
    }

    .add_to_bag {
      width: unset;
      height: unset;
      padding: 0;
      border: none;
      background: transparent;
      font-weight: 500;

      &:before {
        content: "+";
        color: var(--clr-primary-300);
        font-weight: 500;
      }
    }

    .modify_cart {
      height: unset;
      padding: 0;
    }

    .saleTag {
      position: unset;
      padding: 0;
      font-size: 0.75rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: var(--clr-primary-300);

      svg {
        font-size: 1rem;
        padding-right: 5px;
        display: inline-block;
      }
    }
  }
}
