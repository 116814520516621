@import "styles/mixins";

.kind {
  color: var(--clr-neutral-800);
  border-radius: 50px;
  background: var(--clr-neutral-150);
  padding: 1px 6px;
  text-transform: capitalize;
  border: 1px solid var(--clr-neutral-300);
  &:hover {
    border: 1px solid var(--clr-neutral-600);
    color: var(--clr-neutral-800);
  }
}

.type {
  @extend .kind;
  min-width: fit-content;
}

.subtype {
  @extend .kind;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include mobile() {
    // max-width:18ch;
  }
}