@import "styles/mixins";

.button {
  display: block;
  width: fit-content;
  padding: 0.5em 1.2em;
  border-radius: 50px;
  font-weight: 500;
  border: 1px solid;
  line-height: 1.4rem;
  color: var(--clr-neutral-800);
  background: var(--clr-neutral-250);
  border-color: var(--clr-neutral-250);
  &:hover {
    cursor: pointer;
    background: var(--clr-secondary-300);
    border-color: var(--clr-secondary-300);
    color: var(--clr-neutral-100);
  }
}

.primary {
  background: var(--clr-primary-300);
  border-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  border-radius: 50px;
  &:hover {
    background: var(--clr-secondary-300);
    border-color: var(--clr-secondary-300);
  }
  &:focus {
    outline: none;
    background: var(--clr-accent-300);
    border-color: var(--clr-accent-300);
  }
}

.secondary {
  background: var(--clr-secondary-300);
  border-color: var(--clr-secondary-300);
  color: var(--clr-neutral-100);
  &:hover {
    background: var(--clr-primary-300);
    border-color: var(--clr-primary-300);
  }
}

.accent {
  background: var(--clr-accent-300);
  border-color: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  &:hover {
    background: var(--clr-secondary-300);
    border-color: var(--clr-secondary-300);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid transparent;
  padding: 0px;
  border-radius: var(--border-radius-sm);
  font-size: 1.625rem;
  color: var(--clr-neutral-800);
  position: relative;
  height: 44px;
  width: 44px;
  &:hover {
    color: var(--clr-neutral-800);
    border: 1px solid var(--clr-neutral-250);
    background-color: var(--clr-neutral-150);
  }
  @include mobile() {
    width: 38px;
  }
  &--light {
    color: var(--clr-neutral-300);
  }
}

.instagram:hover {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}
.facebook:hover {
  background-color: #4267b2;
}
.youtube:hover {
  background-color: #ff0000;
}
.twitter:hover {
  background-color: #1da1f2;
}

.location {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  text-align: left;
  font-size: 0.875rem;
  color: var(--clr-neutral-900);
  border: 1px solid transparent;
  // border-radius: var(--border-radius);
  overflow: hidden;
  padding: 5px 6px;
  background-color: var(--clr-neutral-100);
  &:hover {
    border: 1px solid var(--clr-neutral-250);
    border-color: var(--clr-neutral-250);
    background-color: var(--clr-neutral-150);
    svg {
      color: var(--clr-primary-300);
    }
  }
  @include tablet() {
    width: 100%;
    // border: 0px;
    height: 41px;
    border-radius: 0px;
    padding: 0.5rem 1rem;
    background-color: var(--clr-neutral-175);
    color: var(--clr-neutral-800);
    &:hover {
      border: 1px solid var(--clr-neutral-250);
      background-color: var(--clr-neutral-150);
    }
  }
}

.xmark:hover {
  color: var(--clr-danger);
}

.transparent {
  background: transparent;
}

.sliderLink {
  display: flex;
  // gap: 5px;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0 0 0 5px;
  text-transform: capitalize;
  white-space: break-spaces;
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: underline dotted var(--clr-neutral-400);
  text-underline-offset: 4px;
  &:hover {
    background-color: transparent;
    color: var(--clr-primary-300);
    text-decoration: underline;
  }
  svg {
    padding-right: 5px;
  }
  span {
    text-decoration: none !important;
  }
}

.link {
  background: transparent;
  border: none;
  padding: 0px;
  &:hover {
    background: transparent;
    color: var(--clr-primary-300);
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

.inactive {
  background: var(--clr-neutral-600);
  color: var(--clr-neutral-100);
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
    background: var(--clr-neutral-600);
    border-color: var(--clr-neutral-250);
    color: var(--clr-neutral-100);
  }
}

.w-100 {
  width: 100%;
}

.center-text,
.text-center {
  text-align: center;
}

.toggle {
  font-size: 1.125rem;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  outline: none;
  transition: border-color 0.3s;
  display:flex;
  align-items: center;
  gap:3px;

  &:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:last-of-type {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:hover {
    border-color: var(--clr-primary-300);
  }

  &-inactive:first-of-type {
    // background: red;
    border-right: 0px;
  }
  &-inactive:last-of-type {
    // background: red;
    border-left: 0px;
  }

  &-active {
    background: var(--clr-primary-25);
    color: var(--clr-primary-300);
    border-color: var(--clr-primary-300);
    &:hover {
      background: var(--clr-primary-25);
      color: var(--clr-primary-300);
    }
  }
  &-inactive {
    background: transparent;
    color: var(--clr-neutral-700);
    border-color: var(--clr-neutral-300);
    &:hover {
      background-color: transparent;
      color: var(--clr-primary-300);
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  border-radius: 100px;
  padding: 8px 16px;
  height: 100%;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--clr-neutral-800);
  text-transform: capitalize;
  &:hover {
    border: 1px solid var(--clr-neutral-300);
    background-color: var(--clr-neutral-150);
    color: var(--clr-neutral-800);
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 43.98px;
    height: 43.98px;
    background: var(--clr-neutral-100);
    border: 1px solid var(--clr-neutral-250);
    border-radius: 100px;
    font-size: 0.9375rem;
    font-weight: 500;
    padding: 0px;
    color: var(--clr-neutral-800);
    svg {
      width: 20px;
      height: 20px;
      color: var(--clr-neutral-800);
    }
  }
}

.danger {
  &:hover {
    color: var(--clr-neutral-100);
    background: var(--clr-danger);
    border-color: var(--clr-danger);
  }
}
.danger-alt {
  &:hover {
    color: var(--clr-danger);
    background: var(--clr-neutral-125);
    // border-color:var(--clr-neutral-100);
  }
}

.slider-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--clr-primary-300);
  border: 1px solid var(--clr-primary-300);
  border-radius: 50px;
  width: 48px;
  height: 48px;
  padding: 0px;
  svg {
    font-size: 1.5rem;
    color: var(--clr-neutral-100);
  }
  &:hover {
    background: var(--clr-primary-400);
    border-color: var(--clr-primary-400);
    svg {
      color: var(--clr-neutral-100);
    }
  }
}

.disabled {
  background: var(--clr-neutral-650);
  border-color: var(--clr-neutral-650);
  &:hover {
    background: var(--clr-neutral-600);
    border-color: var(--clr-neutral-600);
    cursor: not-allowed;
  }
}

.hero {
  background: var(--clr-accent-300);
  border-color: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  font-size: 1.25rem;
  @include tablet() {
    font-size: 1.125rem;
  }
}

.google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: var(--clr-neutral-150);
  border-radius: 5px;
  transition: all 0.3s;
  &:hover {
    background: var(--clr-neutral-200);
    border-color: var(--clr-primary-300);
    color: var(--clr-neutral-800);
  }
}

.favorite,
.not-favorite {
  background: none;
  padding: 0.5rem;
  border-color: transparent;
  &:hover {
    background: var(--clr-neutral-100);
    border-color: var(--clr-neutral-200);
    color: var(--clr-accent-300);
  }
}
.favorite {
  color: var(--clr-accent-300);
}
.not-favorite {
  color: var(--clr-neutral-300);
}

.currentRefinement {
  background: var(--clr-primary-300);
  border-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    font-size: 1.125rem;
  }
  &:hover {
    background: var(--clr-danger);
    border-color: var(--clr-danger);
  }
}
