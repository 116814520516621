@import "styles/mixins";

.skeleton {
  background: var(--clr-neutral-250);
  margin-bottom: 10px;
  border-radius: 3px;
}

.wrapper {
  position: relative;
  overflow:hidden;
}

.skeleton.text {
  width: 100%;
  height: 14px;
  margin:0px;
  margin-bottom:7px;
}

.skeleton.title {
  width: 60%;
  height: 20px;
  margin-bottom: 12px;
}
.skeleton.title-large {
  width: 400px;
  height: 50px;
  margin-bottom: 12px;
}

.skeleton.button {
  width: 100px;
  height: 30px;
  margin: 12px 0px;
}
.skeleton.button-large {
  width: 100px;
  height: 40px;
  margin-bottom:0px;
}

.skeleton.thumbnail {
  width: 100%;
  height: 200px;
}

.skeleton.header {
  width:100%;
  height:450px;
}

.skeleton.headerText {
  width: 300px;
  height: 40px;
  margin:0px;
  @include mobile() {
    height:30px;
  }
}

.skeleton.headerIcon {
  height: 48px;
  width: 48px;
  border-radius:50%;
  margin:0px;
  flex-shrink: 0;
}

.skeleton.spacer {
  width:100%;
  height: 10px;
  background-color: transparent;
}
.skeleton.spacer-small {
  width:100%;
  height: 5px;
  background-color: transparent;
}

.skeleton.hero {
  width:100%;
}

.skeleton.product-card {
  width: 280px;
  height: 472px;
  margin-bottom: 10px;
  border-radius:var(--border-radius-md)

}

.skeleton.special {
  width: 100%;
  height: 285px;
  margin-bottom: 10px;
  border-radius:var(--border-radius)

}

.dealCard {
  width: 100%;
  height: 100%;
  border: 1px solid var(--clr-neutral-250);
  // padding: calc(1em - 10px) 1em;
  .skeleton.thumbnail {
    margin:unset;
  }
  .skeleton.text {
    // margin-bottom:unset!important;
  }
  .content {
    flex-wrap: wrap;
    padding: 1rem;
  }
}
.card {
  background: var(--clr-neutral-175);
  border: 1px solid var(--clr-neutral-250);
  padding: calc(1em - 10px) 1em;
}

.textHeader {
  display:flex;
  align-items: center;
  gap:1rem;
  padding:1rem;
  height: 98px;
  @include tablet(){
    padding:1rem 0rem;
  }
  @include mobile() {
    height:80px;
  }
}

.header {
  --max-width: 1200px;
  --padding: 1rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
  @include tablet() {
    --padding: 0rem;
  }
}

.hero {
  // --max-width: 1400px;
  // width: min(var(--max-width), 100%);
  // margin-inline: auto;
  position: relative;
  height:630px;
  width: 100%;
  @include tablet() {
    height: 100vh;
    width: 100%;
    min-height:350px;
    max-height: 700px;
  }
}

// animation effects
.shimmer_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  animation: loading 1.5s infinite ease-in-out;
}

.shimmer {
  width:50%;
  height:100%;
  // background:red;
  background:rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.15)
}

@keyframes loading {
  0% { transform: translateX(-100%)}
  100% {transform: translateX(150%)}
}