.brand {
  color: var(--clr-neutral-700);
  width: fit-content;
  font-size: 0.75rem;
  line-height: 0.6rem;
  font-weight: 600;
  &:hover {
    color: var(--clr-primary-300);
    text-decoration: underline;
  }
}