@import "styles/variables";
@import "styles/mixins";

.swiper,
.swiper-wrappper {
  z-index: 0 !important;
  &:hover{
    cursor:grab;
  }
}
.swiper-button-disabled {
  opacity:0!important;
  background:transparent!important;
  pointer-events: none;
}


.steps-slider .swiper-button-disabled {
  opacity: 0!important;
  background:red;
}

.swiper-pagination-bullet-active {
  background-color: var(--clr-primary-300) !important;
}

#hero-pagination .swiper-pagination-bullet-active {
  background-color: white !important;
}


.product-slider {
  position:relative;
}

.our-brands-slider .swiper-slide {
  width: 280px;
  a {
    display: flex;
    gap:1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   .image-container{
    position: relative;
    width: 330px;
    height:250px;
    }
    img {
      transition: transform 0.5s ease;
      object-fit: contain;
    }
    &:hover img {
      transform:scale(1.05);
      transition: transform 0.5s ease;
    }
  }
  .name {
    color:var(--clr-neutral-150);
    font-size:1.25rem;
    font-weight:500;
  }
}

.brands-slider .swiper-slide {
  a {
    border-radius: var(--border-radius);
    background: var(--clr-neutral-100);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    height: 200px;
    width:100%;
    svg {
      height: 125px;
      width: 125px;
      object-fit: contain;
      transition: transform 0.5s ease;
    }
    &:hover {
      svg {
        transform: scale(1.05);
        transition: transform 0.5s ease;
      }
    }
  }
}

.category-image-slider .swiper-slide {
  width: 320px;
  .icon {
    opacity: 0;
    transition: all 0.2s ease;
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding:1em;
    font-size: 1rem;
    gap: 0.5rem;
    transition: box-shadow 0.2s ease;
    white-space: nowrap;
  }
  &:hover .icon {
    color: var(--clr-neutral-100);
    opacity: 1;
    transition: all 0.2s ease;
  }
  &:hover img {
    transform: scale(1.05);
  }

  .body {
    display: flex;
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    bottom: 0;
    color: var(--clr-neutral-150);
    width: 100%;
    padding: 0.8rem 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .image-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 100vh;
    max-height: 500px;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: top;
      transition: all 0.75s ease;
    }
    &:after {
      content: "";
      height: 100px;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 0;
    }
  }
}



.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--clr-neutral-100);
  @include mobile(){
    bottom:50px;
  }
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--clr-accent-300);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.slider-tabs {
  .swiper-slide {
    height:auto;
    overflow:visible;
    width:300px;
    min-width: 300px;
  }
}