@import "styles/mixins";

.container {
  position: relative;
  margin-left:auto;
  height:100%;
  display:flex;
  align-items: center;
  max-width:300px;
  @include tablet(){
    max-width:unset;
  }
}

.location {
  &__content {
    display: flex;
    gap: 8px;
    background: none;
    justify-content: flex-start;
    align-items: center;
    line-height: 16px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    @include mobile(){
      width:100%;
      max-width: unset;
    }
    svg:first-of-type {
      display: flex;
      color: var(--clr-primary-200);
      font-size:1.5rem;
      margin: 0;
      @include tablet(){
        font-size:1.25rem;
      }
    }
    svg {
      margin-left: auto;
    }
  }
  &__text {
    color: var(--clr-neutral-800);
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 4px;
    line-height: 0.875rem;
    flex-wrap: wrap;
    width:100%;
    max-width: 250px;
    overflow:hidden;
    @include tablet(){
      width:100%;
      max-width: unset;
      flex-direction: row;
      flex-wrap:nowrap;
    }
  }
}

.orderType {
  font-weight: 600;
  white-space: nowrap;
  width: fit-content;
  overflow: hidden; 
  text-overflow: ellipsis; 
  // width: 100%;
  @include tablet(){
    // width:100%;
    // max-width: unset;
    text-overflow: unset;
    overflow:unset;
  }
}

.storeName {
  white-space: nowrap;
  font-weight: normal;
  width: fit-content;
  overflow: hidden; 
  text-overflow: ellipsis;
  width: 100%;
}

.active {
  border-color: var(--clr-primary-300)!important;
}


