@import "styles/mixins";

.search {
  flex:1;
  @include tablet(){
    padding:1rem;
  }
}

.close {
  padding-bottom:1rem;
  display:none;
  justify-content: flex-end;
  @include tablet(){
    display:flex;
  }
}

.searchBox {
  justify-content: center;
  // width: 100%;
  overflow: auto;
  flex: 1;
  form {
    width: 100%;
    border:none;
    font-size: 1rem;
  }
  input {
    border: 1px solid var(--clr-neutral-250);
    // border-radius: var(--border-radius);
    border-radius:50px;
    background-color: var(--clr-neutral-150);
  }
  &__content {
    display: flex;
    gap:1em;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: auto;
  }
  &__close {
    // font-size: var(--fs-700);
    color: var(--clr-neutral-800);
    &:hover {
      color: var(--clr-danger);
      cursor: pointer;
    }
  }
  
}

.searchResults {
  width: 100%;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 1;

  padding: 1em 0em;
  background:var(--clr-neutral-150);
  border-top: 1px solid var(--clr-neutral-250);
  border-bottom: 1px solid var(--clr-neutral-250);

  .content {
    display:grid;
    // padding:1rem;
    // grid-template-columns: 2fr 5fr;
    // gap:1em;
    // @include tablet(){
    //   grid-template-columns: 1fr;
    // }
  }

  .suggestions {
    // background:red;
    @include tablet(){
      display:none;
    }
  }

  .results {
    display:flex;
    flex-direction: column;
    overflow:hidden;
    padding:1rem;
    &__title {
      display:flex;
      justify-content: space-between;
      padding-bottom:1em;
      flex-wrap:wrap;
      gap:1rem;
    }
    ol {
      display:flex;
      gap:15px;
      overflow-x: scroll;
      padding-bottom:1em;
      li > div {
        min-width:225px;
        width:100%;
      }
    }
    @include tablet(){
      padding:0rem;
    }
  }
  
  @include tablet() {
    position:unset;
    border:none;
    background:white;
    width:100%;
  }
}

.no_results {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  color: var(--clr-neutral-800);
  padding:1rem;
  svg {
    color:var(--clr-danger);
    font-size:3rem;
  }
}

.backdrop {
  opacity: 1;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: calc(var(--nav-size) + 1px);
  right: 0;
  left: 0;
  // transition: opacity 0.2s;
  z-index: -1;
  pointer-events: all;
  backdrop-filter: blur(5px);
  @include tablet() {
    top: calc(var(--nav-size) + 1px);
  }
  @include tablet () {
    display:none;
  }
}